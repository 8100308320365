import { Link } from "gatsby";
import React from "react";
import Logo from "./Logo";

function Header({ isHome }) {
  return (
    <header className="antialiased px-4 pt-12 md:pt-10">
      <div className="flex flex-col items-center justify-center max-w-6xl mx-auto ">
        <div className="flex-none w-full md:px-24 pb-10 md:pb-8">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        {isHome && (
          <nav className="border-b border-black uppercase">
            <Link to="/contact" title="Contact">
              Contact
            </Link>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Header;
