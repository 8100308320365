import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children, isHome, isContact }) {
  return (
    <div
      id="top"
      className={`${
        isContact ? `bg-black text-white` : `bg-white text-black`
      } flex flex-col min-h-screen text-sm`}
    >
      <Header isHome={isHome} />

      <main className="flex-1 w-full max-w-7xl px-4 mx-auto">{children}</main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
