import React from "react";

function Logo() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        x="0"
        y="0"
        enableBackground="new 0 0 697.5 30"
        overflow="visible"
        viewBox="0 0 697.5 30"
        className="h-full w-full fill-current hidden md:block px-24"
      >
        <path d="M27.16 0.81L27.16 12.24 14.19 12.24 14.19 0.81 0 0.81 0 29.19 14.19 29.19 14.19 19.95 27.16 19.95 27.16 29.19 41.35 29.19 41.35 0.81z"></path>
        <path d="M45.79 0.81L45.79 29.19 86.9 29.19 86.9 21.49 59.98 21.49 59.98 18.85 86.9 18.85 86.9 11.15 59.98 11.15 59.98 8.51 86.9 8.51 86.9 0.81z"></path>
        <path d="M124.01 0.81L124.01 15.57 107.1 0.81 91.33 0.81 91.33 29.19 105.52 29.19 105.52 14.43 122.35 29.19 138.2 29.19 138.2 0.81z"></path>
        <path d="M179.24 19.18c4.99-1.42 6.93-3.08 6.93-8.11 0-6.73-3.04-10.26-10.09-10.26h-33.44v28.38h14.19v-8.07h7.05l7.22 8.07h16.82l-8.68-10.01zm-11.19-4.75h-11.31V8.11h11.31c1.7 0 3.93 0 3.93 2.96 0 3.36-2.18 3.36-3.93 3.36"></path>
        <path d="M190.61 0.81H204.8V29.189999999999998H190.61z"></path>
        <path d="M235.99 0.81L223.42 10.58 223.42 0.81 209.24 0.81 209.24 29.19 223.42 29.19 223.42 24.12 229.26 19.7 237.9 29.19 253.67 29.15 238.83 12.12 253.55 0.81z"></path>
        <path d="M326.81 0.81L326.81 29.19 367.91 29.19 367.91 21.49 341 21.49 341 18.85 367.91 18.85 367.91 11.15 341 11.15 341 8.51 367.91 8.51 367.91 0.81z"></path>

        <path d="M499.94 19.18c4.99-1.42 6.93-3.08 6.93-8.11 0-6.73-3.04-10.26-10.09-10.26h-33.45v28.38h14.19v-8.07h7.05l7.22 8.07h16.82l-8.67-10.01zm-11.19-4.75h-11.31V8.11h11.31c1.7 0 3.93 0 3.93 2.96 0 3.36-2.19 3.36-3.93 3.36"></path>
        <path d="M417.78 0.81L417.78 29.19 458.89 29.19 458.89 21.49 431.97 21.49 431.97 18.85 458.89 18.85 458.89 11.15 431.97 11.15 431.97 8.51 458.89 8.51 458.89 0.81z"></path>
        <path d="M371.06 0.81L371.06 8.51 385.77 8.51 385.77 29.19 399.96 29.19 399.96 8.51 414.64 8.51 414.64 0.81z"></path>
        <path d="M314.09.81h-35.35v28.38h14.19v-8.07h21.16c3.85 0 9.57 0 9.57-9.93 0-10.38-6.2-10.38-9.57-10.38m-8.39 13.22h-12.77V8.51h12.77c1.26 0 3.77 0 3.77 2.8 0 2.72-2.39 2.72-3.77 2.72M531.54 30c13.86 0 20.84-1.78 20.84-9.28 0-5.63-2.8-7.78-13.95-8.67-9.36-.73-14.27 0-14.27-2.55 0-2.19 2.27-2.88 6.69-2.88 4.66 0 6.24 1.18 6.85 4.01h14.19c0-7.34-7.54-10.62-21.04-10.62-13.91 0-20.88 2.31-20.88 9.49 0 7.82 7.18 8.23 16.95 8.63 8.19.32 11.27.57 11.27 2.59 0 2.35-2.15 2.47-6.65 2.47-5.15 0-6.85-.81-7.46-3.69h-14.19c0 7.3 6.45 10.5 21.65 10.5M577.14 30c13.86 0 20.84-1.78 20.84-9.28 0-5.63-2.8-7.78-13.95-8.67-9.36-.73-14.27 0-14.27-2.55 0-2.19 2.27-2.88 6.69-2.88 4.66 0 6.24 1.18 6.85 4.01h14.19c0-7.34-7.54-10.62-21.04-10.62-13.91 0-20.88 2.31-20.88 9.49 0 7.82 7.18 8.23 16.95 8.63 8.19.32 11.27.57 11.27 2.59 0 2.35-2.15 2.47-6.65 2.47-5.15 0-6.85-.81-7.46-3.69h-14.19c.01 7.3 6.45 10.5 21.65 10.5M647.33 11.23C646.52 2.96 638.25 0 624.14 0c-14.39 0-22.18 2.84-23.15 11.23-.12 1.05-.16 2.27-.16 3.45 0 1.7.08 3.32.16 4.09C601.84 27 610.11 30 624.14 30c13.99 0 22.21-2.96 23.19-11.23.12-1.01.16-2.27.16-3.45 0-1.66-.08-3.28-.16-4.09m-23.19 11.8c-6.69 0-8.72-1.17-8.72-7.99s2.03-8.07 8.72-8.07c7.17 0 8.76 1.3 8.76 8.07s-1.54 7.99-8.76 7.99"></path>
        <path d="M683.31 0.81L683.31 15.57 666.41 0.81 650.64 0.81 650.64 29.19 664.83 29.19 664.83 14.43 681.65 29.19 697.5 29.19 697.5 0.81z"></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="418.76"
        height="72.19"
        x="0"
        y="0"
        enableBackground="new 0 0 418.76 72.19"
        overflow="visible"
        viewBox="0 0 418.76 72.19"
        className="h-full w-full fill-current md:hidden px-4"
      >
        <path d="M109.7 0L109.7 11.43 96.73 11.43 96.73 0 82.54 0 82.54 28.38 96.73 28.38 96.73 19.14 109.7 19.14 109.7 28.38 123.89 28.38 123.89 0z"></path>
        <path d="M128.33 0L128.33 28.38 169.44 28.38 169.44 20.68 142.52 20.68 142.52 18.04 169.44 18.04 169.44 10.34 142.52 10.34 142.52 7.7 169.44 7.7 169.44 0z"></path>
        <path d="M206.55 0L206.55 14.76 189.64 0 173.87 0 173.87 28.38 188.06 28.38 188.06 13.62 204.89 28.38 220.74 28.38 220.74 0z"></path>
        <path d="M261.78 18.37c4.99-1.42 6.93-3.08 6.93-8.11 0-6.73-3.04-10.26-10.09-10.26h-33.44v28.38h14.19v-8.07h7.05l7.22 8.07h16.82l-8.68-10.01zm-11.18-4.75h-11.31V7.3h11.31c1.7 0 3.93 0 3.93 2.96-.01 3.36-2.18 3.36-3.93 3.36"></path>
        <path d="M273.15 0H287.34V28.38H273.15z"></path>
        <path d="M318.53 0L305.96 9.77 305.96 0 291.78 0 291.78 28.38 305.96 28.38 305.96 23.31 311.8 18.89 320.44 28.38 336.21 28.34 321.38 11.31 336.1 0z"></path>
        <g>
          <path d="M48.07 43L48.07 71.38 89.17 71.38 89.17 63.68 62.26 63.68 62.26 61.04 89.17 61.04 89.17 53.34 62.26 53.34 62.26 50.7 89.17 50.7 89.17 43z"></path>
          <path d="M221.2 61.37c4.99-1.42 6.93-3.08 6.93-8.11 0-6.73-3.04-10.26-10.09-10.26h-33.45v28.38h14.19v-8.07h7.05l7.22 8.07h16.82l-8.67-10.01zm-11.19-4.75H198.7V50.3h11.31c1.7 0 3.93 0 3.93 2.96 0 3.36-2.19 3.36-3.93 3.36"></path>
          <path d="M139.04 43L139.04 71.38 180.15 71.38 180.15 63.68 153.23 63.68 153.23 61.04 180.15 61.04 180.15 53.34 153.23 53.34 153.23 50.7 180.15 50.7 180.15 43z"></path>
          <path d="M92.32 43L92.32 50.7 107.03 50.7 107.03 71.38 121.22 71.38 121.22 50.7 135.9 50.7 135.9 43z"></path>
          <path d="M35.35 43H0v28.38h14.19v-8.07h21.16c3.85 0 9.57 0 9.57-9.93C44.92 43 38.72 43 35.35 43m-8.39 13.22H14.19V50.7h12.77c1.26 0 3.77 0 3.77 2.8 0 2.72-2.39 2.72-3.77 2.72M252.8 72.19c13.86 0 20.84-1.78 20.84-9.28 0-5.63-2.8-7.78-13.95-8.67-9.36-.73-14.27 0-14.27-2.55 0-2.19 2.27-2.88 6.69-2.88 4.66 0 6.24 1.18 6.85 4.01h14.19c0-7.34-7.54-10.62-21.04-10.62-13.91 0-20.88 2.31-20.88 9.49 0 7.82 7.18 8.23 16.95 8.63 8.19.32 11.27.57 11.27 2.59 0 2.35-2.15 2.47-6.65 2.47-5.15 0-6.85-.81-7.46-3.69h-14.19c0 7.3 6.45 10.5 21.65 10.5M298.4 72.19c13.86 0 20.84-1.78 20.84-9.28 0-5.63-2.8-7.78-13.95-8.67-9.36-.73-14.27 0-14.27-2.55 0-2.19 2.27-2.88 6.69-2.88 4.66 0 6.24 1.18 6.85 4.01h14.19c0-7.34-7.54-10.62-21.04-10.62-13.91 0-20.88 2.31-20.88 9.49 0 7.82 7.18 8.23 16.95 8.63 8.19.32 11.27.57 11.27 2.59 0 2.35-2.15 2.47-6.65 2.47-5.15 0-6.85-.81-7.46-3.69h-14.19c.01 7.3 6.45 10.5 21.65 10.5M368.59 53.42c-.81-8.27-9.08-11.23-23.19-11.23-14.39 0-22.18 2.84-23.15 11.23-.12 1.05-.16 2.27-.16 3.45 0 1.7.08 3.32.16 4.09.85 8.23 9.12 11.23 23.15 11.23 13.99 0 22.21-2.96 23.19-11.23.12-1.01.16-2.27.16-3.45 0-1.66-.08-3.28-.16-4.09m-23.19 11.8c-6.69 0-8.72-1.17-8.72-7.99s2.03-8.07 8.72-8.07c7.17 0 8.76 1.3 8.76 8.07s-1.54 7.99-8.76 7.99"></path>
          <path d="M404.57 43L404.57 57.76 387.67 43 371.9 43 371.9 71.38 386.09 71.38 386.09 56.62 402.91 71.38 418.76 71.38 418.76 43z"></path>
        </g>
      </svg>
    </div>
  );
}

export default Logo;
